<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Name Input -->
          <b-col cols="6">
            <b-form-group id="input-name">
              <label for="nameInput">Name: <span class="text-danger">*</span></label>
              <b-form-input
                id="nameInput"
                name="name"
                v-model="editedItem.name"
                type="text"
                aria-describedby="input-name-feedback"
                placeholder="Shipping name..."
                data-vv-as="name"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-validate="{ required: true, min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- status -->
          <b-col cols="6">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select v-model="filter_status" :options="statusList" name="Status">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template></b-form-select
              >
            </b-form-group>
          </b-col>
          <!-- Handler Input -->
          <b-col cols="12" v-if="isCreated">
            <b-form-group id="input-handler">
              <label for="handlerInput">Handler: <span class="text-danger">*</span></label>
              <b-form-input
                id="handlerInput"
                name="handler"
                v-model="editedItem.handler"
                type="text"
                aria-describedby="input-handler-feedback"
                placeholder="Shipping handler..."
                data-vv-as="handler"
                :class="{ input: true, 'is-danger': errors.has('handler') }"
                v-validate="{ required: true, min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('handler')" class="is-danger-text position-relative">{{
                errors.first('handler')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- Fee Input -->
          <b-col cols="4">
            <b-form-group id="input-fee">
              <label for="feeInput">Fee: <span class="text-danger">*</span></label>
              <b-form-input
                trim
                id="feeInput"
                name="fee"
                v-model="editedItem.fee"
                type="number"
                aria-describedby="input-fee-feedback"
                placeholder="Shipping fee..."
                data-vv-as="fee"
                :class="{ input: true, 'is-danger': errors.has('fee') }"
                v-validate="{ required: true, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('fee')" class="is-danger-text position-relative">{{ errors.first('fee') }}</span>
            </b-form-group>
          </b-col>
          <!-- Rate Input -->
          <b-col cols="4">
            <b-form-group id="input-rate">
              <label for="rateInput">Rate: <span class="text-danger">*</span></label>
              <b-form-input
                trim
                id="rateInput"
                name="rate"
                v-model="editedItem.rate"
                type="number"
                aria-describedby="input-rate-feedback"
                placeholder="Shipping rate..."
                data-vv-as="rate"
                :class="{ input: true, 'is-danger': errors.has('rate') }"
                v-validate="{ required: true, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('rate')" class="is-danger-text position-relative">{{
                errors.first('rate')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- displayFee Input -->
          <b-col cols="4">
            <b-form-group id="input-displayFee">
              <label for="displayFeeInput">Display Fee: <span class="text-danger">*</span></label>
              <b-form-input
                trim
                id="displayFeeInput"
                name="displayFee"
                v-model="editedItem.display_fee"
                type="number"
                aria-describedby="input-display-fee-feedback"
                placeholder="Shipping Display Fee..."
                data-vv-as="displayFee"
                :class="{ input: true, 'is-danger': errors.has('displayFee') }"
                v-validate="{ required: true, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('displayFee')" class="is-danger-text position-relative">{{
                errors.first('displayFee')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- input Image Url -->
          <b-col cols="6">
            <b-form-group id="input-img-url" label="Image URL:" label-for="img-url" aria-describedby="input-image-help">
              <b-form-input
                id="img-url"
                name="imageUrl"
                v-model="editedItem.image_url"
                type="text"
                placeholder="Image URL..."
                data-vv-as="Image URL"
                :class="{ input: true, 'is-danger': errors.has('imageUrl') }"
                v-validate="{ min: 3, max: 256 }"
              ></b-form-input>
              <span v-show="errors.has('imageUrl')" class="is-danger-text position-relative">{{
                errors.first('imageUrl')
              }}</span>
              <b-form-text id="input-image-help">Enter exist image link or upload new image file.</b-form-text>
            </b-form-group>
          </b-col>
          <!-- choose Image file -->
          <b-col cols="6">
            <b-form-group id="input-img-file" label="Image File:" label-for="img-file">
              <b-form-file
                v-model="editedItem.image_file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Shipping List</h4>
      </b-col>
      <!-- FilterName -->
      <b-col cols="4">
        <b-form-group id="input-name-filter" label="Shipping" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search shippings name..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- Filter Active -->
      <b-col cols="2">
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="6" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem">Add</b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(image_preview)="item">
        <!-- {{ item.value }} -->
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>
      <template v-slot:cell(fee)="item">
        {{ formatPrice(item.item.fee) }}
      </template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import LightBox from 'vue-image-lightbox';
import _ from 'lodash';
import axios from 'axios';
export default {
  name: 'Shipping',
  components: {
    LightBox
  },
  data() {
    return {
      isCreated: true,
      search: '',
      isBusy: false,
      filter_name: '',
      filter_category: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Name', key: 'name' },
        { text: 'Handler', key: 'handler' },
        { text: 'Image Url', key: 'image_preview' },
        { text: 'Fee', key: 'fee' },
        { text: 'Rate', key: 'rate' },
        { text: 'Display Fee', key: 'display_fee' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      // screenPosList: [],
      categoryAdsList: [],
      modalTitle: '',
      // loading: false,
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        handler: '',
        image_url: '',
        fee: '',
        rate: '',
        display_fee: '',
        status: '',
        image_file: null
      },
      dialog: false,
      editedIndex: -1
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('name') ||
        !this.editedItem.name ||
        this.errors.has('fee') ||
        this.errors.has('rate') ||
        this.errors.has('displayFee')
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Shipping method' }]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    fetchData() {
      this.loading = true;
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      ApiService.get(
        'shippings',
        `?name=${this.filter_name}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      )
        .then((resp) => {
          const shippingRes = resp;
          this.items = shippingRes.data.data.shippingList.map((ads) => {
            return {
              ...ads,
              image_preview: [{ thumb: ads.image_url, src: ads.image_url }]
            };
          });
          this.rows = shippingRes.data.data.total;
          this.isBusy = false;
        })
        .catch((err) => {
          this.$bvToast.toast(`${err}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.isCreated = true;
      this.dialog = true;
      this.modalTitle = 'Add Shipping';
    },
    editItem: function (item) {
      this.isCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update Shipping';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`shippings/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Shipping deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      }
    },
    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        name: '',
        handler: '',
        image_url: '',
        fee: '',
        rate: '',
        display_fee: '',
        status: '',
        image_file: null
      };
      this.editedIndex = -1;

      this.filter_status = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Validate required fields
      // Get new brand/cate/vendor name for data-table
      this.editedItem.status = this.filter_status;
      let formData = new FormData();
      for (const key in this.editedItem) {
        if (this.editedItem.hasOwnProperty(key)) {
          const element = this.editedItem[key];
          if (element !== '' && element !== null) {
            formData.append(key, element);
          }
        }
      }
      if (this.editedIndex > -1) {
        // Update Shipping
        axios({
          method: 'PUT',
          url: `shippings/${this.items[this.editedIndex].id}`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              this.editedItem.image_preview = [
                {
                  thumb: this.editedItem.image_url,
                  src: this.editedItem.image_url
                }
              ];
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Shipping updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast('errors', {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      } else {
        axios({
          method: 'POST',
          url: 'shippings',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.data.status) {
              this.items.unshift({
                ...response.data.data,
                image_preview: [
                  {
                    thumb: response.data.data.image_url,
                    src: response.data.data.image_url
                  }
                ]
              });
              this.close();
              this.$bvToast.toast('Shipping added successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.$bvToast.toast('errors', {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    }
  }
};
</script>
